.logo{
    width: 137px;
    height: 56px;    
  }
  
  .marcoBordeado {
      border-radius: 8px;
      padding: 30px;
      border-top: 2px solid whitesmoke;
      border-left: 2px solid whitesmoke;
     /* background-image: linear-gradient(to right,#ffffff, #ffffff); */
  }
  
  .textoBlanco{
    color: white;
    font-size: 20px;
  }

  .fondoLogin {
    
    width: 100%;
    height: 700px;
    background-image: url("../../imagenes/logo.jpg");
    background-position: center;
    background-repeat: no-repeat;
  }
  
  
  
  input:invalid{
    color: red;
  }
  
  input:invalid+span:after {
    margin-left: -5px;
    position:absolute;
    content: 'x';
    color: red;
    font-size: 20px;
    font: bold;
  }
  
  input:required:invalid {
    border-color: red;
  }
  
  
  
  input:valid{
    color: green;
  }
  
  input:valid+span:after {
    margin-left: -10px;
    position:absolute;
    content: '✓';
    color:green;
    font-size: 30px;
    font: bold;
  }
  
  input:required:valid {
    border-color: green;
  }
  